import React, { useState } from 'react';
import { Globe, Search, Database } from 'lucide-react';
import { analyzeSitemap } from '../lib/api/sitemapAnalyzer';
import { SitemapNode } from '../types/sitemap';
import SitemapVisualizer from './sitemap/SitemapVisualizer';
import ContentOpportunities from './content/ContentOpportunities';
import ActionItems from './action/ActionItems';
import GSCInsights from './gsc/GSCInsights';

const Assists = () => {
  const [domain, setDomain] = useState('assistedlivingmagazine.com');
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [sitemapData, setSitemapData] = useState<SitemapNode[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [showGSC, setShowGSC] = useState(false);

  const handleAnalyze = async () => {
    setIsAnalyzing(true);
    setError(null);
    
    try {
      const data = await analyzeSitemap(domain);
      setSitemapData(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to analyze site');
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-4">
            <Globe className="w-6 h-6 text-indigo-600" />
            <h2 className="text-2xl font-bold text-gray-900">Site Analysis</h2>
          </div>
          <button
            onClick={() => setShowGSC(!showGSC)}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-700"
          >
            <Database className="h-4 w-4" />
            {showGSC ? 'Hide GSC Data' : 'Show GSC Data'}
          </button>
        </div>

        <div className="flex gap-4 mb-4">
          <input
            type="text"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            placeholder="Enter website domain..."
            className="flex-1 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          />
          <button
            onClick={handleAnalyze}
            disabled={isAnalyzing}
            className="bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition-colors flex items-center gap-2 disabled:opacity-50"
          >
            <Search className="w-5 h-5" />
            {isAnalyzing ? 'Analyzing...' : 'Analyze Site'}
          </button>
        </div>

        {error && (
          <div className="p-4 bg-red-50 text-red-700 rounded-lg mb-4">
            {error}
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 gap-6">
        <SitemapVisualizer data={sitemapData} isLoading={isAnalyzing} />
        
        {showGSC && <GSCInsights domain={domain} />}
        
        <ContentOpportunities data={sitemapData} />
        <ActionItems data={sitemapData} />
      </div>
    </div>
  );
};

export default Assists;