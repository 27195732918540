import React, { useState, useEffect } from 'react';
import { TrendingUp, Search, BarChart2, ArrowUp, ArrowDown } from 'lucide-react';
import { SearchConsoleService } from '../../lib/services/searchConsoleService';
import GoogleAuthButton from './GoogleAuthButton';
import LoadingSpinner from '../shared/LoadingSpinner';
import { SearchQuery, PagePerformance } from '../../lib/types/searchAnalytics';

interface Props {
  domain: string;
}

const GSCInsights: React.FC<Props> = ({ domain }) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'queries' | 'pages'>('queries');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const service = SearchConsoleService.getInstance();
        
        if (!service.isAuthenticated()) {
          setLoading(false);
          return;
        }

        const analytics = await service.getSearchAnalytics(domain);
        setData(analytics);
      } catch (error) {
        console.error('Error fetching GSC data:', error);
        setError('Failed to fetch search console data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [domain]);

  if (loading) {
    return <LoadingSpinner message="Loading search insights..." />;
  }

  if (!SearchConsoleService.getInstance().isAuthenticated()) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-semibold mb-4">Google Search Console Insights</h3>
        <p className="text-gray-600 mb-4">
          Connect your Google Search Console account to see search performance insights.
        </p>
        <GoogleAuthButton />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="text-red-600">{error}</div>
      </div>
    );
  }

  const renderMetricChange = (value: number, isPositive: boolean) => (
    <span className={`flex items-center gap-1 text-sm ${isPositive ? 'text-green-600' : 'text-red-600'}`}>
      {isPositive ? <ArrowUp className="h-4 w-4" /> : <ArrowDown className="h-4 w-4" />}
      {Math.abs(value)}%
    </span>
  );

  return (
    <div className="space-y-6">
      {/* Overview Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="text-lg font-medium">Total Clicks</h3>
              <p className="text-3xl font-bold mt-2">{data.totals.clicks.toLocaleString()}</p>
            </div>
            <div className="p-3 bg-purple-50 rounded-full">
              <TrendingUp className="h-6 w-6 text-purple-600" />
            </div>
          </div>
          {renderMetricChange(12.5, true)}
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="text-lg font-medium">Impressions</h3>
              <p className="text-3xl font-bold mt-2">{data.totals.impressions.toLocaleString()}</p>
            </div>
            <div className="p-3 bg-blue-50 rounded-full">
              <Search className="h-6 w-6 text-blue-600" />
            </div>
          </div>
          {renderMetricChange(8.3, true)}
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="text-lg font-medium">Avg. Position</h3>
              <p className="text-3xl font-bold mt-2">{data.totals.position.toFixed(1)}</p>
            </div>
            <div className="p-3 bg-green-50 rounded-full">
              <BarChart2 className="h-6 w-6 text-green-600" />
            </div>
          </div>
          {renderMetricChange(0.5, false)}
        </div>
      </div>

      {/* Detailed Analysis */}
      <div className="bg-white rounded-lg shadow">
        <div className="border-b border-gray-200">
          <div className="flex">
            <button
              onClick={() => setActiveTab('queries')}
              className={`px-6 py-3 text-sm font-medium ${
                activeTab === 'queries'
                  ? 'border-b-2 border-purple-600 text-purple-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Top Queries
            </button>
            <button
              onClick={() => setActiveTab('pages')}
              className={`px-6 py-3 text-sm font-medium ${
                activeTab === 'pages'
                  ? 'border-b-2 border-purple-600 text-purple-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Top Pages
            </button>
          </div>
        </div>

        <div className="p-6">
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <th className="px-6 py-3">{activeTab === 'queries' ? 'Query' : 'Page'}</th>
                  <th className="px-6 py-3">Clicks</th>
                  <th className="px-6 py-3">Impressions</th>
                  <th className="px-6 py-3">Position</th>
                  <th className="px-6 py-3">CTR</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {(activeTab === 'queries' ? data.queries : data.pages).map((item: SearchQuery | PagePerformance, index: number) => (
                  <tr key={index} className="text-sm">
                    <td className="px-6 py-4 text-gray-900">
                      {activeTab === 'queries' ? item.query : item.page}
                    </td>
                    <td className="px-6 py-4 text-gray-600">{item.clicks}</td>
                    <td className="px-6 py-4 text-gray-600">{item.impressions}</td>
                    <td className="px-6 py-4 text-gray-600">{item.position.toFixed(1)}</td>
                    <td className="px-6 py-4 text-gray-600">
                      {((item.clicks / item.impressions) * 100).toFixed(1)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GSCInsights;