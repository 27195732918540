import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchConsoleService } from '../../lib/services/searchConsoleService';
import LoadingSpinner from '../shared/LoadingSpinner';

const AuthCallback: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuth = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      
      if (!code) {
        setError('No authorization code received');
        return;
      }

      try {
        const service = SearchConsoleService.getInstance();
        await service.handleAuthCallback(code);
        navigate('/assists');
      } catch (err) {
        setError('Failed to authenticate with Google Search Console');
        console.error(err);
      }
    };

    handleAuth();
  }, [navigate]);

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-red-600 mb-2">Authentication Error</h2>
          <p className="text-gray-600">{error}</p>
          <button
            onClick={() => navigate('/')}
            className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            Return Home
          </button>
        </div>
      </div>
    );
  }

  return <LoadingSpinner message="Authenticating with Google Search Console..." />;
};

export default AuthCallback;