import React, { useState, useMemo } from 'react';
import { Lightbulb, Search } from 'lucide-react';
import { AnalysisResult, PageData } from '../../lib/api/types';

interface Props {
  data: AnalysisResult;
}

const ContentOpportunities: React.FC<Props> = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState<'all' | 'high' | 'medium' | 'low'>('all');

  const opportunities = useMemo(() => {
    if (!data?.pages) return [];
    
    return data.pages
      .map(page => ({
        ...page,
        opportunity: calculateOpportunity(page)
      }))
      .filter(page => {
        if (filter !== 'all') {
          return page.opportunity.level === filter;
        }
        return true;
      })
      .filter(page => 
        page.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        page.url.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => b.opportunity.score - a.opportunity.score);
  }, [data?.pages, searchTerm, filter]);

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
        <Lightbulb className="h-5 w-5 text-indigo-600" />
        Content Opportunities
      </h3>

      <div className="flex gap-4 mb-6">
        <div className="flex-1">
          <input
            type="text"
            placeholder="Search opportunities..."
            className="w-full p-2 border border-gray-300 rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <select
          className="p-2 border border-gray-300 rounded-lg"
          value={filter}
          onChange={(e) => setFilter(e.target.value as any)}
        >
          <option value="all">All Opportunities</option>
          <option value="high">High Impact</option>
          <option value="medium">Medium Impact</option>
          <option value="low">Low Impact</option>
        </select>
      </div>

      <div className="space-y-4">
        {opportunities.map((page, index) => (
          <OpportunityCard key={page.url + index} page={page} />
        ))}
        {opportunities.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No opportunities found matching your criteria.
          </div>
        )}
      </div>
    </div>
  );
};

interface OpportunityCardProps {
  page: PageData & { opportunity: ReturnType<typeof calculateOpportunity> };
}

const OpportunityCard: React.FC<OpportunityCardProps> = ({ page }) => {
  return (
    <div className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow">
      <div className="flex items-center justify-between mb-2">
        <h4 className="font-medium">{page.title || new URL(page.url).pathname}</h4>
        <span className={`px-2 py-1 text-xs rounded-full ${getImpactColor(page.opportunity.level)}`}>
          {page.opportunity.level} Impact
        </span>
      </div>
      <p className="text-sm text-gray-600 mb-2">{page.opportunity.reason}</p>
      <div className="text-sm text-gray-500">
        Score: {page.contentScore} | Words: {page.wordCount}
      </div>
    </div>
  );
};

function calculateOpportunity(page: PageData) {
  if (page.contentScore < 30) {
    return {
      level: 'high' as const,
      score: 3,
      reason: 'Low content quality score - needs significant improvement'
    };
  }
  
  if (page.wordCount < 300) {
    return {
      level: 'high' as const,
      score: 2,
      reason: 'Thin content - needs expansion'
    };
  }

  if (page.contentScore < 60) {
    return {
      level: 'medium' as const,
      score: 1,
      reason: 'Content could be enhanced for better engagement'
    };
  }

  return {
    level: 'low' as const,
    score: 0,
    reason: 'Content is performing well'
  };
}

function getImpactColor(level: 'high' | 'medium' | 'low') {
  switch (level) {
    case 'high':
      return 'bg-red-100 text-red-800';
    case 'medium':
      return 'bg-yellow-100 text-yellow-800';
    case 'low':
      return 'bg-green-100 text-green-800';
  }
}

export default ContentOpportunities;