import React, { useState } from 'react';
import { Globe2, ArrowRight, Building2 } from 'lucide-react';

interface WebsiteSelectorProps {
  onSelect: (website: string) => void;
}

const WebsiteSelector: React.FC<WebsiteSelectorProps> = ({ onSelect }) => {
  const [website, setWebsite] = useState('');

  const quickSelect = [
    {
      name: 'assistedlivingmagazine.com',
      description: 'Senior Living & Care Resources',
      icon: Building2
    }
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (website) {
      onSelect(website.toLowerCase().replace(/^https?:\/\//, '').replace(/\/$/, ''));
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white rounded-xl shadow-xl p-8">
        <div className="flex items-center gap-3 mb-6">
          <Globe2 className="h-8 w-8 text-purple-600" />
          <h1 className="text-2xl font-bold text-gray-900">
            Analyze Your Website
          </h1>
        </div>

        <form onSubmit={handleSubmit} className="mb-8">
          <div className="flex gap-3">
            <input
              type="text"
              placeholder="Enter your website URL..."
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              className="flex-1 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
            <button
              type="submit"
              className="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition-colors flex items-center gap-2"
            >
              Analyze
              <ArrowRight className="h-5 w-5" />
            </button>
          </div>
        </form>

        <div>
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Quick Select</h2>
          <div className="space-y-3">
            {quickSelect.map((site) => (
              <button
                key={site.name}
                onClick={() => onSelect(site.name)}
                className="w-full flex items-center gap-4 p-4 border border-gray-200 rounded-lg hover:border-purple-300 hover:bg-purple-50 transition-colors"
              >
                <site.icon className="h-6 w-6 text-purple-600" />
                <div className="text-left">
                  <div className="font-medium text-gray-900">{site.name}</div>
                  <div className="text-sm text-gray-600">{site.description}</div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteSelector;