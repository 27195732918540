import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Globe, Building2 } from 'lucide-react';
import Assists from './components/Assists';
import WebsiteSelector from './components/WebsiteSelector';
import AuthCallback from './components/auth/AuthCallback';

function App() {
  const [selectedWebsite, setSelectedWebsite] = useState('');

  return (
    <BrowserRouter>
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50">
        <nav className="bg-white shadow-lg">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16 items-center">
              <div className="flex items-center">
                <Globe className="h-8 w-8 text-purple-600" />
                <span className="ml-2 text-xl font-bold text-gray-900">Memetics.ai</span>
              </div>
            </div>
          </div>
        </nav>

        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <Routes>
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route
              path="/"
              element={
                !selectedWebsite ? (
                  <WebsiteSelector onSelect={setSelectedWebsite} />
                ) : (
                  <>
                    <div className="bg-white rounded-xl shadow-xl p-6 mb-8">
                      <div className="flex items-center justify-between">
                        <div>
                          <h1 className="text-3xl font-bold text-gray-900 mb-2">
                            Site Analysis
                          </h1>
                          <div className="flex items-center gap-2 text-gray-600">
                            <Building2 className="h-5 w-5" />
                            <span>{selectedWebsite}</span>
                            <button 
                              onClick={() => setSelectedWebsite('')}
                              className="text-purple-600 hover:text-purple-700 text-sm font-medium ml-2"
                            >
                              Change Website
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white rounded-xl shadow-xl p-6">
                      <Assists website={selectedWebsite} />
                    </div>
                  </>
                )
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;