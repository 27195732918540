import React, { useMemo, useState } from 'react';
import { ChevronDown, ChevronRight, Globe, FileText } from 'lucide-react';

interface SitemapNode {
  url: string;
  children: SitemapNode[];
  type: 'folder' | 'file';
  name: string;
  metrics?: {
    contentScore?: number;
    wordCount?: number;
    lastModified?: Date;
  };
}

interface Props {
  data: SitemapNode[];
  isLoading?: boolean;
}

const SitemapVisualizer: React.FC<Props> = ({ data = [], isLoading = false }) => {
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());

  const toggleNode = (url: string) => {
    const newExpanded = new Set(expandedNodes);
    if (newExpanded.has(url)) {
      newExpanded.delete(url);
    } else {
      newExpanded.add(url);
    }
    setExpandedNodes(newExpanded);
  };

  const expandAll = () => {
    const allUrls = getAllUrls(data);
    setExpandedNodes(new Set(allUrls));
  };

  const collapseAll = () => {
    setExpandedNodes(new Set());
  };

  const stats = useMemo(() => {
    if (!Array.isArray(data)) return { total: 0, files: 0, folders: 0 };
    
    const counts = {
      total: 0,
      files: 0,
      folders: 0
    };

    const countNodes = (nodes: SitemapNode[]) => {
      nodes.forEach(node => {
        counts.total++;
        if (node.type === 'file') {
          counts.files++;
        } else {
          counts.folders++;
          countNodes(node.children);
        }
      });
    };

    countNodes(data);
    return counts;
  }, [data]);

  const renderNode = (node: SitemapNode, depth: number = 0) => {
    const isExpanded = expandedNodes.has(node.url);
    const hasChildren = node.children && node.children.length > 0;
    const paddingLeft = `${depth * 1.5}rem`;

    return (
      <div key={node.url}>
        <div 
          className="flex items-center py-2 hover:bg-gray-50 cursor-pointer"
          style={{ paddingLeft }}
          onClick={() => hasChildren && toggleNode(node.url)}
        >
          <span className="mr-2">
            {hasChildren ? (
              isExpanded ? (
                <ChevronDown className="w-4 h-4 text-gray-500" />
              ) : (
                <ChevronRight className="w-4 h-4 text-gray-500" />
              )
            ) : (
              <FileText className="w-4 h-4 text-gray-400" />
            )}
          </span>
          <span className="text-sm text-gray-700">{node.name}</span>
          {node.metrics && (
            <div className="ml-auto flex items-center space-x-4 text-xs text-gray-500 pr-4">
              {node.metrics.contentScore && (
                <span>Score: {node.metrics.contentScore}</span>
              )}
              {node.metrics.wordCount && (
                <span>Words: {node.metrics.wordCount}</span>
              )}
            </div>
          )}
        </div>
        {isExpanded && hasChildren && (
          <div className="border-l border-gray-200 ml-6">
            {node.children.map(child => renderNode(child, depth + 1))}
          </div>
        )}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!Array.isArray(data) || data.length === 0) {
    return (
      <div className="text-center p-8 text-gray-500">
        No sitemap data available. Click "Analyze Site" to begin.
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-4 border-b border-gray-200">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <Globe className="w-5 h-5 text-indigo-600" />
            <h3 className="text-lg font-semibold text-gray-900">Site Structure</h3>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={expandAll}
              className="px-3 py-1 text-sm text-indigo-600 hover:bg-indigo-50 rounded"
            >
              Expand All
            </button>
            <button
              onClick={collapseAll}
              className="px-3 py-1 text-sm text-indigo-600 hover:bg-indigo-50 rounded"
            >
              Collapse All
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mb-4">
          <div className="bg-gray-50 p-3 rounded">
            <div className="text-sm font-medium text-gray-500">Total Pages</div>
            <div className="text-2xl font-bold text-gray-900">{stats.total}</div>
          </div>
          <div className="bg-gray-50 p-3 rounded">
            <div className="text-sm font-medium text-gray-500">Files</div>
            <div className="text-2xl font-bold text-gray-900">{stats.files}</div>
          </div>
          <div className="bg-gray-50 p-3 rounded">
            <div className="text-sm font-medium text-gray-500">Folders</div>
            <div className="text-2xl font-bold text-gray-900">{stats.folders}</div>
          </div>
        </div>
      </div>
      <div className="overflow-auto max-h-[600px]">
        {data.map(node => renderNode(node))}
      </div>
    </div>
  );
};

function getAllUrls(nodes: SitemapNode[]): string[] {
  const urls: string[] = [];
  const traverse = (node: SitemapNode) => {
    urls.push(node.url);
    node.children?.forEach(traverse);
  };
  nodes.forEach(traverse);
  return urls;
}

export default SitemapVisualizer;