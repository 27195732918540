import axios from 'axios';
import { GOOGLE_CONFIG } from '../config/google';
import { SearchAnalyticsData } from '../types/searchAnalytics';

const SEARCH_CONSOLE_API = 'https://www.googleapis.com/webmasters/v3';

export class SearchConsoleService {
  private static instance: SearchConsoleService;
  private accessToken: string | null = null;

  private constructor() {
    this.accessToken = localStorage.getItem('gsc_access_token');
  }

  static getInstance(): SearchConsoleService {
    if (!SearchConsoleService.instance) {
      SearchConsoleService.instance = new SearchConsoleService();
    }
    return SearchConsoleService.instance;
  }

  async handleAuthCallback(code: string): Promise<void> {
    try {
      const params = new URLSearchParams({
        code,
        client_id: GOOGLE_CONFIG.clientId,
        client_secret: GOOGLE_CONFIG.clientSecret,
        redirect_uri: GOOGLE_CONFIG.redirectUri,
        grant_type: 'authorization_code'
      });

      const response = await axios.post(
        'https://oauth2.googleapis.com/token',
        params.toString(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      this.accessToken = response.data.access_token;
      localStorage.setItem('gsc_access_token', this.accessToken);
      
      if (response.data.refresh_token) {
        localStorage.setItem('gsc_refresh_token', response.data.refresh_token);
      }
    } catch (error) {
      console.error('Auth error:', error);
      throw new Error('Authentication failed');
    }
  }

  async refreshToken(): Promise<void> {
    const refreshToken = localStorage.getItem('gsc_refresh_token');
    if (!refreshToken) throw new Error('No refresh token available');

    try {
      const params = new URLSearchParams({
        client_id: GOOGLE_CONFIG.clientId,
        client_secret: GOOGLE_CONFIG.clientSecret,
        refresh_token: refreshToken,
        grant_type: 'refresh_token'
      });

      const response = await axios.post(
        'https://oauth2.googleapis.com/token',
        params.toString(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      this.accessToken = response.data.access_token;
      localStorage.setItem('gsc_access_token', this.accessToken);
    } catch (error) {
      console.error('Token refresh error:', error);
      throw new Error('Token refresh failed');
    }
  }

  async getSearchAnalytics(domain: string): Promise<SearchAnalyticsData> {
    if (!this.accessToken) {
      throw new Error('Not authenticated');
    }

    try {
      const endDate = new Date().toISOString().split('T')[0];
      const startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];

      const response = await axios.post(
        `${SEARCH_CONSOLE_API}/sites/${encodeURIComponent(`https://${domain}`)}/searchAnalytics/query`,
        {
          startDate,
          endDate,
          dimensions: ['query', 'page'],
          rowLimit: 1000
        },
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`
          }
        }
      );

      return this.processAnalyticsData(response.data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        await this.refreshToken();
        return this.getSearchAnalytics(domain);
      }
      console.error('Search analytics error:', error);
      throw new Error('Failed to fetch search analytics');
    }
  }

  private processAnalyticsData(data: any): SearchAnalyticsData {
    const queries = new Map<string, { clicks: number; impressions: number; position: number }>();
    const pages = new Map<string, { clicks: number; impressions: number; position: number }>();
    const totals = { clicks: 0, impressions: 0, position: 0 };

    data.rows?.forEach((row: any) => {
      const [query, page] = row.keys;
      
      if (!queries.has(query)) {
        queries.set(query, { clicks: 0, impressions: 0, position: 0 });
      }
      const queryData = queries.get(query)!;
      queryData.clicks += row.clicks;
      queryData.impressions += row.impressions;
      queryData.position = row.position;

      if (!pages.has(page)) {
        pages.set(page, { clicks: 0, impressions: 0, position: 0 });
      }
      const pageData = pages.get(page)!;
      pageData.clicks += row.clicks;
      pageData.impressions += row.impressions;
      pageData.position = row.position;

      totals.clicks += row.clicks;
      totals.impressions += row.impressions;
      totals.position += row.position;
    });

    return {
      queries: Array.from(queries.entries()).map(([query, data]) => ({
        query,
        ...data
      })),
      pages: Array.from(pages.entries()).map(([page, data]) => ({
        page,
        ...data
      })),
      totals: {
        clicks: totals.clicks,
        impressions: totals.impressions,
        position: totals.position / (data.rows?.length || 1)
      }
    };
  }

  isAuthenticated(): boolean {
    return !!this.accessToken;
  }

  clearAuth(): void {
    this.accessToken = null;
    localStorage.removeItem('gsc_access_token');
    localStorage.removeItem('gsc_refresh_token');
  }
}