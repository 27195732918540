import axios from 'axios';

export async function fetchUrl(url: string): Promise<string> {
  try {
    const response = await axios.get(url, {
      headers: {
        'Accept': 'text/html,application/xhtml+xml,application/xml',
        'User-Agent': 'Memetics-SEO-Analyzer/1.0'
      },
      timeout: 10000
    });
    return response.data;
  } catch (error) {
    // Convert error to a simple object that can be cloned
    const errorMessage = error instanceof Error ? error.message : 'Error fetching URL';
    throw new Error(errorMessage);
  }
}