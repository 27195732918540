import React, { useMemo } from 'react';
import { CheckSquare, AlertTriangle } from 'lucide-react';
import { AnalysisResult, PageData } from '../../lib/api/types';

interface Props {
  data: AnalysisResult;
}

const ActionItems: React.FC<Props> = ({ data }) => {
  const actions = useMemo(() => generateActionItems(data), [data]);

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
        <CheckSquare className="h-5 w-5 text-indigo-600" />
        Action Items
      </h3>

      <div className="space-y-4">
        {actions.map((action, index) => (
          <div
            key={index}
            className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow"
          >
            <div className="flex items-center gap-2 mb-2">
              <AlertTriangle className={`h-5 w-5 ${getPriorityColor(action.priority)}`} />
              <h4 className="font-medium">{action.title}</h4>
              <span className={`px-2 py-1 text-xs rounded-full ${getPriorityBadgeColor(action.priority)}`}>
                {action.priority} Priority
              </span>
            </div>
            <p className="text-sm text-gray-600 mb-2">{action.description}</p>
            {action.pages && (
              <div className="text-sm text-gray-500">
                Affected pages: {action.pages}
              </div>
            )}
          </div>
        ))}
        {actions.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No action items found. Your site is in good shape!
          </div>
        )}
      </div>
    </div>
  );
};

function generateActionItems(data: AnalysisResult) {
  if (!data?.pages) return [];
  
  const actions = [];
  const pages = data.pages;

  // Content Quality
  const lowQualityPages = pages.filter(p => p.contentScore < 30).length;
  if (lowQualityPages > 0) {
    actions.push({
      title: 'Improve Low Quality Content',
      description: `${lowQualityPages} pages have low content quality scores and need improvement.`,
      priority: 'high',
      pages: lowQualityPages
    });
  }

  // Missing Meta Descriptions
  const noMetaDesc = pages.filter(p => !p.description).length;
  if (noMetaDesc > 0) {
    actions.push({
      title: 'Add Missing Meta Descriptions',
      description: `${noMetaDesc} pages are missing meta descriptions.`,
      priority: 'high',
      pages: noMetaDesc
    });
  }

  // Thin Content
  const thinContent = pages.filter(p => p.wordCount < 300).length;
  if (thinContent > 0) {
    actions.push({
      title: 'Expand Thin Content',
      description: `${thinContent} pages have less than 300 words.`,
      priority: 'medium',
      pages: thinContent
    });
  }

  // HTTP Status Issues
  const errorPages = pages.filter(p => p.status >= 400).length;
  if (errorPages > 0) {
    actions.push({
      title: 'Fix Broken Pages',
      description: `${errorPages} pages are returning error status codes.`,
      priority: 'high',
      pages: errorPages
    });
  }

  return actions;
}

function getPriorityColor(priority: string) {
  switch (priority) {
    case 'high':
      return 'text-red-500';
    case 'medium':
      return 'text-yellow-500';
    default:
      return 'text-green-500';
  }
}

function getPriorityBadgeColor(priority: string) {
  switch (priority) {
    case 'high':
      return 'bg-red-100 text-red-800';
    case 'medium':
      return 'bg-yellow-100 text-yellow-800';
    default:
      return 'bg-green-100 text-green-800';
  }
}

export default ActionItems;