import { z } from 'zod';

const googleConfigSchema = z.object({
  clientId: z.string(),
  clientSecret: z.string(),
  apiKey: z.string(),
  searchEngineId: z.string(),
  redirectUri: z.string().default('http://localhost:5173/auth/callback'),
  maxRequestsPerMinute: z.number().default(100)
});

export const GOOGLE_CONFIG = googleConfigSchema.parse({
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID || '',
  clientSecret: import.meta.env.VITE_GOOGLE_CLIENT_SECRET || '',
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY || '',
  searchEngineId: import.meta.env.VITE_GOOGLE_SEARCH_ENGINE_ID || '',
  redirectUri: import.meta.env.VITE_GOOGLE_REDIRECT_URI || 'http://localhost:5173/auth/callback',
  maxRequestsPerMinute: 100
});